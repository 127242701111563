import React from 'react';
import Seo from '../components/seo';

const NotFoundPage = () => (
    <>
        <Seo title="404: Not found" />
        <div>
            Not found
        </div>
    </>
);

export default NotFoundPage;
